pre {
  display: grid;
  white-space: wrap; }
// code
//   white-space: none
.highlight {
  margin: 1.5rem 0 2.5rem;
  padding: 0 !important;
  position: relative;
  &:not(.sans)::before {
    content: "";
    //position: absolute
    top: 0;
    bottom: 0;
    width: 2px;
    left: 3rem;
    background: var(--bg);
    z-index: 10; }
  & & {
    margin: 0; }

  pre {
    padding: 1rem;
    background: var(--accent) !important;
    color: var(--text) !important;
    border-radius: 4px;
    max-width: 100%;
    overflow-x: auto; }

  table {
    display: grid; }

  .lntd {
    width: 100%;
    border: none;
    &:first-child {
      &, pre {
        width: 2.5rem !important;
        padding-left: 0;
        padding-right: 0;
        color: var(--light) !important;
        background-color: var(--theme) !important;
        border-radius: 0.25rem 0 0 0.25rem; }

      pre {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column; } } } }
